import React, { useEffect, useState, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { outfitLoad, vitruvianLoad } from './loader/loader';

const ModelViewer = ({cameraRef,data,isWireframe,isTurntableOn,isOutfitVisible,sceneRef,}) => {
  const outfitRef = useRef();
  const modelRef = useRef();
  const [modelLoaded, setModelLoaded] = useState(false);
  const [outfitLoaded, setOutfitLoaded] = useState(false);
  const [rotation, setRotation] = useState(0);

  const { scene } = useThree();

  const applyWireframe = (model, isWireframe) => {
    model.traverse((child) => {
      if (child.isMesh) {
        child.material.wireframe = isWireframe;
      }
    });
  };

  useEffect(() => {
    if (scene) {
      sceneRef.current = scene;
    }
  }, [scene, sceneRef]);

  useEffect(() => {
    const loadModels = async () => {
      try {
        setModelLoaded(false);
        setOutfitLoaded(false);

        if (data?.modelUrl && cameraRef?.current) {
          const outfit = await outfitLoad(data);
          const model = await vitruvianLoad(data);

          applyWireframe(outfit, isWireframe);
          applyWireframe(model, isWireframe);

          outfitRef.current = outfit;
          modelRef.current = model;

          setOutfitLoaded(true);
          setModelLoaded(true);

          if (cameraRef.current) {
            cameraRef.current.target.set(0, 20, 0);
            cameraRef.current.update();
          }
        }
      } catch (error) {
        console.error('Error loading models:', error);
      }
    };

    loadModels();
  }, [data?.modelUrl, isWireframe, cameraRef, data]);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      if (isTurntableOn) {
        setRotation((prevRotation) => prevRotation + 0.01);
      }
    }, 16);

    return () => clearInterval(rotationInterval);
  }, [isTurntableOn]);

  useEffect(() => {
    if (cameraRef?.current) {
      cameraRef.current.target.y = 23;
      cameraRef.current.object.position.y = 25;
      cameraRef.current.target0.copy(cameraRef.current.target);
      cameraRef.current.zoom0 = cameraRef.current.object.position.distanceTo(cameraRef.current.target);
      cameraRef.current.minPolarAngle = 0;
      cameraRef.current.maxPolarAngle = Math.PI;
      cameraRef.current.enableZoom = true;
      cameraRef.current.update();
    }
  }, [modelLoaded, cameraRef]);

  useEffect(() => {
    if (modelRef.current && outfitRef.current) {
      applyWireframe(modelRef.current, isWireframe);
      applyWireframe(outfitRef.current, isWireframe);
    }
  }, [isWireframe]);

  useFrame(() => {
    if (modelRef.current && outfitRef.current) {
      modelRef.current.rotation.y = rotation;
      outfitRef.current.rotation.y = rotation;
    }
  });

  return (
    <>
      {outfitLoaded && modelLoaded && (
        <group>
          {isOutfitVisible && <primitive object={outfitRef.current} />}
          <primitive object={modelRef.current} />
        </group>
      )}
      {cameraRef && cameraRef.current && (
        <OrbitControls
          enablePan
          enableRotate
          enableZoom
          enableDamping
          dampingFactor={0.1}
          rotateSpeed={0.5}
          minDistance={2}
          maxDistance={20}
          ref={cameraRef}
        />
      )}
    </>
  );
};

export default ModelViewer;
