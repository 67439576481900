import './home.css';
import '../theme/theme.css';
import '../vitruvian/options/options.css';
import Editor from '../vitruvian/editor/editor';
import Generator from '../vitruvian/generator/generator';
import Info from '../info/info';
import ThemeToggle from '../theme/theme';
import VitruvianMediaprocessing from '../vitruvian/mediaprocessing/mediaprocessing';
import Options from '../vitruvian/options/options';
import React, { useState, useEffect } from 'react';

const VitruvianHome = () => {
  const randomTheme=Math.floor(Math.random() * (1 - 0 + 1)) + 0;
  const randomThemeOptionBlurMax=1;
  const randomThemeOptionBlurMin=0;
  const randomThemeOptionBlurStep=0.1;
  const randomThemeOptionBlur=Math.round((Math.random() * (randomThemeOptionBlurMax - randomThemeOptionBlurMin) + randomThemeOptionBlurMin) / randomThemeOptionBlurStep) * randomThemeOptionBlurStep;
  const randomThemeOptionTransparency=Math.random() < 0.5;
  const randomThemeOptionHDRIList = ["city","forest","sunset","dawn","night","warehouse","apartment","studio","lobby","park"];
  const randomThemeOptionHDRI=Math.floor(Math.random() * randomThemeOptionHDRIList.length);

  const [error, setError] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [dataServer, setDataServer] = useState(null);
  const [fileSrc, setFileSrc] = useState(null);
  const [modelUrl, setModelUrl] = useState(null);
  const [modelUuid, setModelUuid] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [texFaceUrl, setTexFaceUrl] = useState(null);
  const [texColorAverageUrl, setTexColorAverageUrl] = useState(null);
  const [themeMode, setThemeMode] = useState('light');

  const [options, setOptions] = useState({
    blurValue: randomThemeOptionBlur,
    environmentBrightness: 0.5,
    environmentPreset: 'sunset',
    isEnvironmentTransparent: randomThemeOptionTransparency,
    isOutfitVisible: true,
    isTurntableOn: false,
    isWireframe: false,
  });

  const handleImageSrcChange = (src) => {
    setTexFaceUrl(null);
    setModelUuid(null);
    setError(null);
    setFileSrc(src.file);
    setImgSrc(src.img);
  };
  
  const handleThemeToggle = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const currentDate = new Date().toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).replace(/\//g, '-');

  useEffect(() => {
    const root = document.documentElement;
    if (themeMode === 'dark') {
      root.style.setProperty('--background-light', 'rgb(33,33,33)');
      root.style.setProperty('--theme-light', 'black');
      root.style.setProperty('--theme-dark', 'white');
      root.style.setProperty('--theme-gradient-direction', 'to top');
    } else {
      root.style.setProperty('--background-light', 'white');
      root.style.setProperty('--theme-light', 'white');
      root.style.setProperty('--theme-dark', 'black');
      root.style.setProperty('--theme-gradient-direction', 'to bottom');
    }
  }, [themeMode]);

  useEffect(() => {
    if (randomTheme === 0){
      setThemeMode('dark');
    } else {
      setThemeMode('light');
    }
    data.options.environmentPreset=data.randomThemes.hdri.name;
  },[]);

  let data = {
    currentDate: currentDate,
    dataServer: dataServer,
    editorData:{
      editorKey:0
    },
    generator:{
      dragDropData:{
        dragDropFile: null,
      },
      generatorEditorData: {
        cropperData:{
          editorWindow:{
            x: null,
            y: null,
            width: null,
            height: null,  
          },
          zoom:1,
        },
        originalImageData:{
          file:fileSrc,
          src:imgSrc,
        },
      },
    },
    mediaprocessing: null,
    modelUrl: modelUrl,
    options: options,
    randomThemes:{
      hdri:{
        index:randomThemeOptionHDRI,
        name:randomThemeOptionHDRIList[randomThemeOptionHDRI],
      },
      theme:randomTheme,
      transparency:randomThemeOptionTransparency,
    },
    texFaceUrl: texFaceUrl,
    texColorAverageUrl: texColorAverageUrl,
    uuid: modelUuid,
  };
  
  let mediaprocessing = new VitruvianMediaprocessing(data);
  data.mediaprocessing = mediaprocessing;

  return (
    <div className="themed-home">
      <div className="theme-toggle">
        <ThemeToggle onChange={handleThemeToggle} checked={themeMode === 'dark'} />
      </div>
      <div className="info">
        <Info />
      </div>

      <h1 className="title">VITRUVIAN</h1>
      <div className="container">
        {!showEditor && (
          <Generator
            data={data}
            onImageSrcChange={handleImageSrcChange}
            onUUIDGenerated={(uuid) => setModelUuid(uuid)}
            setDataServer={setDataServer}
            setModelUrl={setModelUrl}
            setShowEditor={setShowEditor}
            setTexColorAverageUrl={setTexColorAverageUrl}
            setTexFaceUrl={setTexFaceUrl}
            showEditor={showEditor}
          />
        )}
        {showEditor && (
          <div>
            {modelUrl ? (
              <Editor data={data}/>
            ) : (
              <p>{error || 'Waiting for the GLB file to be available...'}</p>
            )}
          </div>
        )}
      </div>
        {showEditor && (
          <Options
            data={data}
            onImageSrcChange={handleImageSrcChange}
            options={options}
            optionsUpdate={setOptions}
            setDataServer={setDataServer}
            setModelUrl={setModelUrl}
            setModelUuid={setModelUuid}
            setShowEditor={setShowEditor}
            setTexColorAverageUrl={setTexColorAverageUrl}
            setTexFaceUrl={setTexFaceUrl}
          />
        )}
    </div>
  );
};

export default VitruvianHome;
