import './bugs.css';
import '../theme/theme.css';
import ThemeToggle from '../theme/theme';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Bugs = () => {
  const [themeMode, setThemeMode] = useState('light');
  const navigate = useNavigate();

  const handleThemeToggle = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const root = document.documentElement;
    if (themeMode === 'dark') {
      root.style.setProperty('--theme-light', 'black');
      root.style.setProperty('--theme-light-alpha', 'black');
      root.style.setProperty('--theme-dark', 'white');
      root.style.setProperty('--theme-dark-alpha', 'rgba(201, 220, 30, 0.962)');
      root.style.setProperty('--background-gradient', 'linear-gradient(to top, black, rgb(100, 100, 100))');
    } else {
      root.style.setProperty('--theme-light', 'white');
      root.style.setProperty('--theme-light-alpha', 'white');
      root.style.setProperty('--theme-dark', 'black');
      root.style.setProperty('--theme-dark-alpha', 'black');
      root.style.setProperty('--background-gradient', 'linear-gradient(to top, rgb(100, 100, 100),white)');
    }
  }, [themeMode]);

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className='bugs-background'>
      <div className="theme-toggle">
        <ThemeToggle onChange={handleThemeToggle} checked={themeMode === 'dark'} />
      </div>
      <div className="back-button" onClick={handleBackClick}>
        <svg viewBox="0 0 24 24">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </div>
      <h1 className='bugs-title'>Bugs :</h1>
      <ul className='bugs-items'>
      <li>There is a bug in which the client will properly handle the face detection however the server will fail to process it. Currently, it will return a gray texture instead of the face. Usually, this means that the face is generally hard to detect and requires a different image. But it's worth trying again, maybe with a different zoom level in the generator.</li>
      <li>Generator flickering between the current and previous input. This bug seems to be dependent on your computer's hardware. The best thing to do if this happens is refresh.</li>
      <li>The facemesh displayed in the Generator can sometimes misalign with the second image input. This happens when the aspect ratio of the image inputs change. This does not effect the final result of a generation as the process on the server is different from the client but it is being investigated.</li>
      </ul>
    </div>
  );
};

export default Bugs;
