import React, { useEffect, useRef, useState } from 'react';
import './editor.css';
import '../../theme/theme.css';
import * as THREE from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { Color } from 'three';
import { OrbitControls } from '@react-three/drei';
import ModelViewer from './model-viewer';
import SceneWrapper from './scenewrapper';

let reloadEditorCallback = null;

function Editor({ data }) {
  const cameraRef = useRef();
  const sceneRef = useRef();
  const spotlightColor = new Color(4, 2, 2);
  const [editorKey, setEditorKey] = useState(0);
  const [scene, setScene] = useState(null);

  data.editorSceneRef = sceneRef;

  function TransparentEnvironment() {
    return null;
  }

  function RegularEnvironment() {
    const { Environment } = require('@react-three/drei');
    return (
      <Environment
        background={true}
        blur={data.options.blurValue}
        preset={data.options.environmentPreset}
      />
    );
  }

  useEffect(() => {
    reloadEditorCallback = () => {
      setEditorKey(data.editorData.editorKey + 1);
      data.editorData.editorKey=data.editorData.editorKey+1;
    };
    return () => {
      reloadEditorCallback = null;
    };
  }, []);

  const CleanupHelper = () => {
    const { gl } = useThree();
    useEffect(() => {
      return () => {
        gl.dispose();
      };
    }, [gl]);
    return null;
  };

  return (
    <div className="editor-container" key={editorKey}>
      <Canvas shadows gl={{ alpha: true }}>
        <CleanupHelper />
        <SceneWrapper setScene={setScene} sceneRef={sceneRef} />
        {data.options.isEnvironmentTransparent ? (
          <TransparentEnvironment />
        ) : (
          <RegularEnvironment />
        )}
        <ambientLight intensity={data.options.environmentBrightness} />
        <directionalLight
          castShadow={true}
          intensity={data.options.environmentBrightness}
          position={[5, 5, 5]}
        />
        <spotLight
          color={spotlightColor}
          position={[-50, -50, -100]}
          angle={Math.PI / 4}
          intensity={data.options.environmentBrightness * 0.3}
        />
        <ModelViewer
          cameraRef={cameraRef}
          data={data}
          isWireframe={data.options.isWireframe}
          isTurntableOn={data.options.isTurntableOn}
          isOutfitVisible={data.options.isOutfitVisible}
          sceneRef={sceneRef}
        />
        <OrbitControls
          enablePan={false}
          enableZoom={false}
          ref={cameraRef}
          target={[0, 0, 0]}
          initialPosition={[0, 0, 5]}
        />
      </Canvas>
    </div>
  );
}

export function reloadEditor() {
  if (reloadEditorCallback) {
    reloadEditorCallback();
  }
}

export default Editor;
