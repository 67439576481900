import './generate.css';
import '../../../theme/theme.css';
import Data from '../../../data/data';
import ImgProcessing from '../processing/img';
import VitruvianMediaprocessing from '../../mediaprocessing/mediaprocessing';
import { reloadEditor } from '../../editor/editor';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';

const Generate = ({ data, landmarks,onImageSrcChange, onUUIDGenerated, selectedFile,setDataServer,setModelUrl,setShowEditor,setTexColorAverageUrl,setTexFaceUrl,showEditor}) => {
  let [globalUuid,setGlobalUuid]=useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const img = new Image();
  const retryLimit = 40; // one minute

  useEffect(() => {
    if (showEditor) {
      setLoading(false);
    }
  }, [showEditor]);

  const setEditorState = async (imgSrc,uuidClean) => {
    const dataServerInstance = new Data(
      data.currentDate,
      imgSrc,
      uuidClean,
      setDataServer,
      setError,
      setModelUrl,
    );
    data.dataServer = await dataServerInstance.vitruvianDataServer();
    data.modelUrl=`/vitruvian_${data.dataServer._extractions.gender.identity[0]}.glb`
    let mediaprocessing = new VitruvianMediaprocessing(data);
    data.mediaprocessing = mediaprocessing;

    setLoading(false);
    setShowEditor(true);
    reloadEditor();
  }

  const checkModelAvailability = async (uuid,retries = retryLimit) => {
    const imgSrc = data.generator.generatorEditorData.originalImageData.src;

    if (imgSrc && uuid) {
      const uuidClean=uuid.split('.')[0];
      const texFaceUrl=`https://phi-vitruvian2358.s3.us-west-2.amazonaws.com/public/dev/${data.currentDate}/${uuidClean}/tex_head.jpg`;
      const texColorAverageUrl=`https://phi-vitruvian2358.s3.us-west-2.amazonaws.com/public/dev/${data.currentDate}/${uuidClean}/tex_color_average.jpg`;
  
      try {
        const urls = [texFaceUrl, texColorAverageUrl];
        const responses = await Promise.all(urls.map((url) => fetch(url)));
  
        if (responses.every((response) => response.ok)) {
          setTexColorAverageUrl(texColorAverageUrl);
          setTexFaceUrl(texFaceUrl);
          await setEditorState(imgSrc,uuidClean);
          return;
        }
  
        if (retries > 0) {
          console.log('Files not ready for uuid "',uuidClean,'", retrying...');
          setTimeout(() => checkModelAvailability(uuidClean,retries - 1), 1500);

        } else {
          setError('Files are not available. Please try again later.');
        }
      } catch (error) {
        console.error('An error occurred while checking model availability:', error);
  
        if (retries > 0) {
          setTimeout(() => checkModelAvailability(uuidClean,retries - 1), 1500);
        } else {
          setError('An error occurred while checking model availability. Please try again later.');
        }
      }
    } else {
      console.log('check failure for either imgSrc: ',imgSrc,'or uuid: ',uuid);
    }
  };
  
  const handleUpload = async () => {
    if (!selectedFile) console.log('handleupload could not find selected file: ',selectedFile);

    const uuid = uuidv4();
    setGlobalUuid(uuid);
    onUUIDGenerated(uuid);

    try {
      setLoading(true);
      const dataDest = `dev/${data.currentDate}/${uuid}/data_client.json`;
      const dataClient = {
        style: {
          extractions: {
            head: {
              face: { landmarks },
            },
          },
        },
      };

      const jpgFile = await ImgProcessing(data).convertToJpg(selectedFile);

      onImageSrcChange({
        img:img,file:data.generator.dragDropData.dragDropFile
      });

      await Promise.all([
        Storage.put(
          `dev/${data.currentDate}/${uuid}/img.jpg`,
          jpgFile,
          { level: 'public', contentType: jpgFile.type, region: 'us-west-2' }
        ),
        Storage.put(
          dataDest,
          JSON.stringify(dataClient),
          { level: 'public', contentType: 'application/json', region: 'us-west-2' }
        ),
      ]);

      await checkModelAvailability(uuid);
      data.editorData.editorKey=data.editorData.editorKey+=1
      console.log('File uploaded successfully!');
    } catch (error) {
      console.error('An error occurred during file upload:', error);
      setError('Upload failed. Please try again.');
    }
  };

  const isDisabled = Boolean(loading || showEditor);

  return (
    <div>
      <button 
        className={`generate-button ${loading ? 'loading' : ''}`}
        onClick={handleUpload} 
        disabled={isDisabled}
      >
        {loading ? 'Generating...' : 'Generate'}
      </button>
      <div className="themed-generate-container">
        {globalUuid && <p>ID: {globalUuid}</p>}
      </div>
    </div>
  );
};

export default Generate;
