import * as THREE from 'three';
import './options.css';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import React, { useCallback } from 'react';

export default function OptionDownload({ data}) {
  const downloadGLTFFile = useCallback(() => {
    if (!data.editorSceneRef) {
        console.log('data.editorSceneRef has no current value ',data.editorSceneRef);
        return;
    };

    const exporter = new GLTFExporter();

    data.editorSceneRef.current.traverse((child) => {
      if (child.isMesh) {
        if (!child.material.color) {
          child.material.color = new THREE.Color(0xffffff);
        }
        if (!child.material.isMeshStandardMaterial) {
          child.material = new THREE.MeshStandardMaterial({
            color: child.material.color || 0xffffff,
            map: child.material.map || null,
            normalMap: child.material.normalMap || null,
            roughness: child.material.roughness !== undefined ? child.material.roughness : 1,
            metalness: child.material.metalness !== undefined ? child.material.metalness : 0,
          });
        }
      }
    });

    exporter.parse(
      data.editorSceneRef.current,
      function (gltf) {
        const blob = new Blob([JSON.stringify(gltf, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'vitruvian.gltf';
        a.click();
        URL.revokeObjectURL(url);
      },
      { binary: true }
    );
  }, [data.editorSceneRef]);

  return (
    <button className="download-button" onClick={downloadGLTFFile}>
        Download GLTF File
    </button>
  );
}
