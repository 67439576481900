import './options.css';
import './dropdown.css';
import Dropdown from './dropdown';
import Generator from '../generator/generator';
import OptionDownload from './optionDownload';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import React, { useEffect,useRef,useState } from 'react';

const Options = ({ data,onImageSrcChange,options,optionsUpdate,setDataServer,setModelUrl,setModelUuid,setShowEditor,setTexColorAverageUrl,setTexFaceUrl}) => {
  const optionsRef=useRef();
  const [scrollOffset, setScrollOffset] = useState(0);

  const handleToggle = (key) => {
    optionsUpdate({
      ...options,
      [key]: !options[key],
    });
  };

  const handleBlurChange = (event, value) => {
    optionsUpdate({
      ...options,
      blurValue: value,
    });
  };

  const handleBrightnessChange = (event, value) => {
    optionsUpdate({
      ...options,
      environmentBrightness: value,
    });
  };

  const handlePresetChange = (event) => {
    optionsUpdate({
      ...options,
      environmentPreset: event.target.value,
    });
  }; 

  useEffect(() => {
    const ref = optionsRef.current;
    const opts = { passive: false };
  
    const handleScrollWheel = (e) => {
      const cropperElement = document.querySelector(".reactEasyCrop_CropArea");
      if (cropperElement && cropperElement.contains(e.target)) {
        return;
      }
      
      e.preventDefault();
      const delta = e.deltaY;
      const containerHeight = ref?.clientHeight || 0;
      const contentHeight = ref?.scrollHeight || 0;
  
      setScrollOffset((prevOffset) => {
        const newOffset = prevOffset - delta * 0.5;
        return Math.max(Math.min(newOffset, 0), containerHeight - contentHeight);
      });
    };
  
    if (ref) {
      ref.addEventListener('wheel', handleScrollWheel, opts);
    }
  
    return () => {
      if (ref) {
        ref.removeEventListener('wheel', handleScrollWheel, opts);
      }
    };
  }, []);
  
  return (
    <div className="options-container" ref={optionsRef}>
      <div
        className="options"
        style={{
          transform: `translateY(${scrollOffset}px)`,
          transition: 'transform 0.1s',
        }}
      >
        <Dropdown title="Generator" defaultOpen={true}>
        <Generator
          data={data}
          onImageSrcChange={onImageSrcChange}
          onUUIDGenerated={(uuid) => setModelUuid(uuid)}
          setDataServer={setDataServer}
          setModelUrl={setModelUrl}
          setShowEditor={setShowEditor}
          setTexColorAverageUrl={setTexColorAverageUrl}
          setTexFaceUrl={setTexFaceUrl}
        />
        </Dropdown>
        <Dropdown title="Environment">
          <div className='option'>
            <span className='themed-option-text'>Transparency</span>
            <Switch
              onChange={() => handleToggle('isEnvironmentTransparent')}
              checked={options.isEnvironmentTransparent}
            />
          </div>
          <div className='option'>
            <span className='themed-option-text'>Blur</span>
            <Slider
              value={options.blurValue}
              onChange={handleBlurChange}
              min={0}
              max={1}
              step={0.025}
            />
          </div>
          <div className='option'>
            <span className='themed-option-text'>Brightness</span>
            <Slider
              value={options.environmentBrightness}
              onChange={handleBrightnessChange}
              min={0}
              max={1}
              step={0.025}
            />
          </div>
          <div className='option'>
            <span className='themed-option-text'>HDRI</span>
            <select className='dropdown-header' id="preset" onChange={handlePresetChange} defaultValue={options.environmentPreset}>
              <option value="city">City</option>
              <option value="forest">Forest</option>
              <option value="sunset">Sunset</option>
              <option value="dawn">Dawn</option>
              <option value="night">Night</option>
              <option value="warehouse">Warehouse</option>
              <option value="apartment">Apartment</option>
              <option value="studio">Studio</option>
              <option value="lobby">Lobby</option>
              <option value="park">Park</option>
            </select>
          </div>
        </Dropdown>
        <div className='option'>
          <span className='themed-option-text'>Outfit</span>
          <Switch
            onChange={() => handleToggle('isOutfitVisible')}
            checked={options.isOutfitVisible}
          />
        </div>
        <div className='option'>
          <span className='themed-option-text'>Turntable</span>
          <Switch
            onChange={() => handleToggle('isTurntableOn')}
            checked={options.isTurntableOn}
          />
        </div>
        <div className='option'>
          <span className='themed-option-text'>Wireframe</span>
          <Switch
            onChange={() => handleToggle('isWireframe')}
            checked={options.isWireframe}
          />
        </div>
          <OptionDownload data={data}/>
        </div>
      </div>
  );
};

export default Options;
