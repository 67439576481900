import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

class VitruvianTextures {
  constructor(data) {
    this.data = data;
    this.textureLoader = new TextureLoader();
  }

  async faceTexture() {
    const texture = await this.textureLoader.loadAsync(this.data.texFaceUrl);
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.flipY = true;
    return texture;
  }
  

  async textureColorAverage(){
    const texture=await this.textureLoader.loadAsync(this.data.texColorAverageUrl);
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.flipY = true;
    return texture;
  }

  async vitruvianTextures() {
    const faceTexture=await this.faceTexture();
    const textureColorAverage = await this.textureColorAverage();
    return { average:textureColorAverage,face:faceTexture };
  }

  async run(){
    return await this.vitruvianTextures();
  }
}

export default VitruvianTextures;
