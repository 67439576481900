import './generator.css';
import '../../theme/theme.css';
import DragDrop from './dragdrop/drag-drop';
import Generate from './generate/generate';
import GeneratorCropper from './cropper/cropper';
import GeneratorEditor from './editor/editor';
import generateTextToImage from '../mediaprocessing/diffusion/text-to-img';
import ImgProcessing from './processing/img';
import React, { useState, useEffect, useRef } from 'react';

const Generator = ({data,onImageSrcChange,onUUIDGenerated,setDataServer,setModelUrl,setShowEditor,setTexColorAverageUrl,setTexFaceUrl,showEditor}) => {
  const cropperRef = useRef(null);
  const imageWrapperRef = useRef(null);
  const img = new Image();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [faceDetected, setFaceDetected] = useState(false);
  const [faceLandmarks, setFaceLandmarks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prompt, setPromptText] = useState('');
  const [uploadImage, setUploadImage] = useState(null);

  const calculateOnScreenCroppedArea = () => {
    // this gives a fake return of a 100x100 square in the center on takes that have a high probability of failure. this happens so quickly that it usually auto-correct's it's self before the user can see it happen
    if (!cropperRef.current) return {
      x:0,
      y:0,
      width:100,
      height:100,
    };

    const cropperElement = cropperRef.current.querySelector('.reactEasyCrop_CropArea');

    try {
      const cropperRect = cropperElement.getBoundingClientRect();
      const imageWrapperRect = imageWrapperRef.current.getBoundingClientRect();
  
      const centerX = cropperRect.width / 2;
      const centerY = cropperRect.height / 2;
  
      const offsetX = cropperRect.left - imageWrapperRect.left;
      const offsetY = cropperRect.top - imageWrapperRect.top;
  
      return {
        x: centerX - cropperRect.width / 2 + offsetX,
        y: centerY - cropperRect.height / 2 + offsetY,
        width: cropperRect.width,
        height: cropperRect.height,
      };
    } catch {
      return {
        x:0,
        y:0,
        width:100,
        height:100,
      }
    }
  };

  const onScreenCroppedArea = calculateOnScreenCroppedArea();

  data.generator.generatorEditorData.cropperData.editorWindow={
    x: onScreenCroppedArea.x,
    y: onScreenCroppedArea.y,
    width: onScreenCroppedArea.width,
    height: onScreenCroppedArea.height,  
  };

  const handleFileChange = async (dragDropFile) => {
    data.generator.dragDropData.dragDropFile=dragDropFile;
    const url = URL.createObjectURL(dragDropFile);
    img.src = url;
    data.generator.generatorEditorData.originalImageData.src = img;
    const { jpgFile, faceDetected, faceLandmarks } = await ImgProcessing(data).run(dragDropFile);

    setFaceDetected(faceDetected);
    setFaceLandmarks(faceLandmarks);
    setCurrentImage(null);
    setCurrentImage(jpgFile);
  };

  const handleGenerateTextToImage = async () => {
    setLoading(true);
    const generatedBlob = await generateTextToImage(prompt);
    const { jpgFile } = await ImgProcessing(data).run(generatedBlob);
    onImageSrcChange({
      img:jpgFile,file:jpgFile
    });
    setCurrentImage(null);
    setCurrentImage(jpgFile);
    setLoading(false);
  };

  const handleCropComplete = (croppedAreaPixels, faceDetected, faceLandmarks,originalFile,uploadFile) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setFaceDetected(faceDetected);
    setFaceLandmarks(faceLandmarks);
    setUploadImage(uploadFile);
    setCurrentImage(null);
    setCurrentImage(originalFile);
  };

  useEffect(() => {
    if (!currentImage){
      const initializeGeneratorImage = async () => {
        const file = data.generator.generatorEditorData.originalImageData.file;
        if (file) {
          try {
            const jpgFile = await ImgProcessing(data).convertToJpg(file);
            setCurrentImage(null);
            setCurrentImage(jpgFile);
          } catch (error) {
            console.error('Error processing image src:', error);
          }
        }
      };
      initializeGeneratorImage();
    }
  });

  useEffect(() => {
    if (showEditor) {
      const recalculateFacemesh = async () => {
        const newCroppedArea = calculateOnScreenCroppedArea();
        data.generator.generatorEditorData.cropperData.editorWindow = {
          x: newCroppedArea.x,
          y: newCroppedArea.y,
          width: newCroppedArea.width,
          height: newCroppedArea.height,
        };
  
        if (uploadImage) {
          const url = URL.createObjectURL(uploadImage);  
          img.src = url;
          const { faceLandmarks } = await ImgProcessing(data).run(uploadImage);
          setFaceLandmarks(faceLandmarks);
        }
      };
  
      recalculateFacemesh();

    }
  }, [data,showEditor, uploadImage]);
  
  const isDisabled = Boolean(loading || showEditor);

  return (
    <div className="generator-container">
      <div className="input-container">
        <div className="diffusion-container">
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPromptText(e.target.value)}
            placeholder="Enter prompt text"
          />
          <button
            className={`generate-button ${loading ? 'loading' : ''}`}
            onClick={handleGenerateTextToImage}
            disabled={isDisabled || prompt.trim() === ""}
          >
            {loading ? 'Generating...' : 'Generate'}
          </button>
        </div>
        <div>or</div>
        <div className="input-container">
          <DragDrop data={data} onImageDrop={handleFileChange} />
        </div>
      </div>

      {currentImage && (
        <div className="image-preview">
          <div className="image-wrapper" ref={imageWrapperRef}>
            <img
              alt="Current Image"
              className="preview-image"
              imgsrc={typeof currentImage === 'string' ? currentImage : URL.createObjectURL(currentImage)}
            />
            {currentImage && (
              <div className="cropper" ref={cropperRef} >
                <GeneratorCropper
                  data={data}
                  imgsrc={typeof currentImage === 'string' ? currentImage : URL.createObjectURL(currentImage)}
                  onCropComplete={handleCropComplete}
                />
                {croppedAreaPixels && (
                  <GeneratorEditor
                    data={data}
                    facemesh={{ faceLandmarks }}
                  />
                )}
                {croppedAreaPixels && (
                  <div
                    className="editor-window"
                    style={{
                      top: `${data.generator.generatorEditorData.cropperData.editorWindow.y}px`,
                      left: `${data.generator.generatorEditorData.cropperData.editorWindow.x}px`,
                      width: `${data.generator.generatorEditorData.cropperData.editorWindow.width}px`,
                      height: `${data.generator.generatorEditorData.cropperData.editorWindow.height}px`,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="themed-generator-confirmation">
        {currentImage ? (
          faceDetected ? (
            <p>Face Detected!</p>
          ) : (
            <p>No Face Detected! Try Zooming In or Clicking the Image</p>
          )
        ) : null}
      </div>

      {faceDetected ? (
        <div className="generate-wrapper">
          <Generate
            data={data}
            landmarks={faceLandmarks}
            onImageSrcChange={onImageSrcChange}
            onUUIDGenerated={onUUIDGenerated}
            selectedFile={uploadImage}
            setDataServer={setDataServer}
            setModelUrl={setModelUrl}
            setShowEditor={setShowEditor}
            setTexColorAverageUrl={setTexColorAverageUrl}
            setTexFaceUrl={setTexFaceUrl}
            showEditor={showEditor}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Generator;
