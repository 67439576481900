class Data {
  constructor(currentDate, imageSrc, modelUuid,setDataServer, setError, setModelUrl) {
    this.currentDate = currentDate;
    this.imageSrc = imageSrc;
    this.modelUuid = modelUuid;
    this.setDataServer = setDataServer;
    this.setError = setError;
    this.setModelUrl = setModelUrl;
  }

  vitruvianDataServer = async() => {
    const { imageSrc, modelUuid, currentDate ,setDataServer, setModelUrl, setError } = this;
    if (imageSrc && modelUuid) {
      const uuid = modelUuid.split('.')[0];
      const dataServer = `https://phi-vitruvian2358.s3.us-west-2.amazonaws.com/public/dev/${currentDate}/${uuid}/data.json`;

      try {
        const response = await fetch(dataServer);
        if (response.ok) {
          const dataServerResponse = await response.json();
          setDataServer(dataServerResponse);
          setModelUrl(`/vitruvian_${dataServerResponse._extractions.gender.identity[0]}.glb`);
          return dataServerResponse;
        } else {
          setError('The GLB data is not available yet. Please wait.');
          setTimeout(this.vitruvianData, 1500);
        }
      } catch (error) {
        console.log('An error occurred while checking data availability:', error);
        setError('An error occurred while checking data availability. Retrying...');
        setTimeout(this.vitruvianData, 1500);
      }
    }
  }  
}


export default Data;
